import { Injectable } from '@angular/core';

import { HttpClient, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UtilityService, Code } from './utility.service';
import { Auth } from 'aws-amplify';
const moment = require('moment');

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(private http: HttpClient, private utilityService: UtilityService) {
    this.countries = utilityService.getCountryCodes();
    this.languages = utilityService.getLanguageCodes();
    Auth.currentSession().then(CognitoUserSession => {
      const token = CognitoUserSession.getIdToken().getJwtToken();
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      });
    });
  }

  headers: HttpHeaders;
  countries: Code[];
  languages: Code[];
 
  public disableQaLink(qa_token: string): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST
      + `/qalink?token=${qa_token}`;
    const options = { headers: this.headers };
    const req = new HttpRequest('DELETE', url, {}, options);
    return this.http.request(req);
  }

  public getLrsReport(content_id, start): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST
     + `/report/${environment.TEST_USER}/statements?all_statements=true&activity_id=${content_id}&start=${start}`;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }

  public getPlayerLmsCourseState(content_id, course_token): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_HOST
     + `/v2/user_course_state?activity_id=${content_id}&token=${course_token}`;
    const options = {};//{ headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }


  public getLmsProgress(content_id, start): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST 
    + `/report/${environment.TEST_USER}/courseProgress?activityId=${content_id}&start=${start}` ;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }

  public getVrmReport(content_id, start): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST 
    + `/report/${environment.TEST_USER}/vrm_progress?activity_id=${content_id}&start=${start}` ;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }


  public getCourseContentURL(content_id): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/contenturl/' + content_id + '/' + environment.TEST_USER;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public resetUserCourseData(content_id): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/course_state/' + encodeURIComponent(content_id) + '/' + environment.TEST_USER;
    const options = { headers: this.headers };
    const req = new HttpRequest('DELETE', url, {}, options);
    return this.http.request(req);
  }
  public getToken(): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/authtoken/users/' + environment.TEST_USER;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public getCourseContentURLWithActivityId(activityId, userId, qalink, period, username): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/activityurl';
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, { activityId, userId, qalink, period, username }, options);
    return this.http.request(req);
  }
  public getAllCourseContents(): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecontents';
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public getAllCourses(): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/courses';
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public getAllCourseCategores(): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecategories';
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }

  public createCourse(course): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/course';
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, course, options);
    return this.http.request(req);
  }
  public createCourseCategory(category): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecategory';
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, category, options);
    return this.http.request(req);
  }
  public createCourseContent(content): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecontent';
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, content, options);
    return this.http.request(req);
  }
  public copyCourseContentPost(copy): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST_PRODUCTION + '/copycourse';
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, copy, options);
    return this.http.request(req);
  }

  public copyCoursecomplete(key): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/copycoursecomplete?key=' + key;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }

  public copyCourseContentGet(key): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/copycourse?key=' + key;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public getCourseActivityByKey(key: string) {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/courseactivity/' + key;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }

  public updateCourse(course, course_id): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/course/' + course_id;
    const options = { headers: this.headers };
    const req = new HttpRequest('PUT', url, course, options);
    return this.http.request(req);
  }
  public updateCourseCategory(category): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecategory';
    const options = { headers: this.headers };
    const req = new HttpRequest('PUT', url, category, options);
    return this.http.request(req);
  }
  public updateCourseContent(content): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecontent/' + content.content_id;
    if (this.getCountryCodeByText(content.country)) {
      content.country = this.getCountryCodeByText(content.country).code;
    }
    if (this.getLanguageCodeByText(content.language)) {
      content.language = this.getLanguageCodeByText(content.language).code;
    }
    const options = { headers: this.headers };
    const req = new HttpRequest('PUT', url, content, options);
    return this.http.request(req);
  }

  public deleteCourseContent(content_id): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/coursecontent/' + content_id;
    const options = { headers: this.headers };
    const req = new HttpRequest('DELETE', url, {}, options);
    return this.http.request(req);
  }
  public activateCourseContent(activity_id): Observable<HttpEvent<any>> {
    const url = environment.LMS_SERVER_PRIVATE_HOST + '/activate?activity_id=' + activity_id;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, {}, options);
    return this.http.request(req);
  }
  public getCountryCodeByText(text): Code {
    let code: Code;
    this.countries.forEach(a => {
      if (a.text.toLowerCase().trim() == text.toLowerCase().trim()) { code = a; }
    });
    return code;
  }
  public getLanguageCodeByText(text): Code {
    let code: Code;
    this.languages.forEach(a => {
      if (a.text.toLowerCase().trim() == text.toLowerCase().trim()) { code = a; }
    });
    return code;
  }
  public getCountryCodeByValue(value): Code {
    let code: Code;
    this.countries.forEach(a => {
      if (a.code.toLowerCase().trim() == value.toLowerCase().trim()) { code = a; }
    });
    return code;
  }
  public getLanguageCodeByValue(value): Code {
    let code: Code;
    this.languages.forEach(a => {
      if (a.code.toLowerCase().trim() == value.toLowerCase().trim()) { code = a; }
    });
    return code;
  }

  public getUserLrsProgressStr(data) {
    let lines = [];
    let map = {};
    data.data.map(function(item) {
    if (!item.registration) { throw new Error(); }
    let courseActivityId =
        item.statement.context.contextActivities.grouping[0].id;
    if (!map[courseActivityId]) {
        map[courseActivityId] = {
          id: item.activity_id,
          registrations: {},
        };
      }
    if (!map[courseActivityId].registrations[item.registration]) {
        map[courseActivityId].registrations[item.registration] = {
          registration: item.registration,
          statements: [],
        };
      }
    map[courseActivityId].registrations[item.registration].statements.push(
        item
      );
    });

    for (let courseActivityId in map) {
      // lines.push(`${courseActivityId}`);
      for (let registration in map[courseActivityId].registrations) {
        map[courseActivityId].registrations[registration].statements.sort(
          function(a, b) {
            return a.timestamp.localeCompare(b.timestamp);
          }
        );

        lines.push(`${registration}`);
        lines.push('<ol>');
        map[courseActivityId].registrations[registration].statements.map(
          (statement) => {
            lines.push(`<li>${this.getStatementStr(statement.statement, false)}</li>`);
          }
        );
        lines.push('</ol>');

      }
    }
    return lines.join('');
  }

  /**
   * return user progress data needed for VRM or mentor
   * @param data
   */
  public getUserDerivedProgressStr(data) {
    let result = {
      totalAttempts: 0,
      lastAttemptTime: null,
      chapterCompletion: {},
      questionResult: {},
      completionTime: null
    };


    let map = {};
    data.data.map(function(item) {
      if (!item.registration) { throw new Error(); }
      let courseActivityId =
          item.statement.context.contextActivities.grouping[0].id;
      if (!map[courseActivityId]) {
        map[courseActivityId] = {
          id: item.activity_id,
          statements: [],
        };
      }
      map[courseActivityId].statements.push(item);
    });

    for (let courseActivityId in map) {
      // lines.push(`${courseActivityId}`);

        map[courseActivityId].statements.sort(
          function(a, b) {
            return a.timestamp.localeCompare(b.timestamp);
          }
        );

        let objectivePassed = false; // only count first pass objective statement, this is due to storyline will send pass on each result page which is duplicated.
        map[courseActivityId].statements.map(
          (statement) => {
            statement = statement.statement;
            if (statement.verb.id == 'http://adlnet.gov/expapi/verbs/experienced') {
              let slideName = statement.object.definition.name.und.split('-');
              if (slideName[2] == 'chapterComplete' && !result.chapterCompletion[slideName[1]]) {
                result.chapterCompletion[slideName[1]] = statement.timestamp;
              }
            } else if (statement.verb.id == 'http://adlnet.gov/expapi/verbs/failed' && statement.object.definition.type =='http://adlnet.gov/expapi/activities/objective') {
                result.totalAttempts++;
                result.lastAttemptTime = statement.timestamp;
              } else if (statement.verb.id == 'http://adlnet.gov/expapi/verbs/passed' && statement.object.definition.type =='http://adlnet.gov/expapi/activities/objective' && !objectivePassed) {
                result.totalAttempts++;
                result.lastAttemptTime = statement.timestamp;
                objectivePassed = true;
              } else if (statement.verb.id == 'http://adlnet.gov/expapi/verbs/passed' && statement.object.definition.type =='http://adlnet.gov/expapi/activities/course') {
                result.completionTime = statement.timestamp;
            } else if (statement.verb.id == 'http://adlnet.gov/expapi/verbs/answered') {
              // get report tag from meta data table from activity id
              let questionId = statement.object.id;
              result.questionResult[questionId] = statement.result;
            }
          }
        );
    }
    return result;
  }

  private getObjectTypeStr(type) {
    return type.substr(type.lastIndexOf('/') + 1).toUpperCase();
  }

  private getStatementStr(statement, showDate) {
    let str = `${moment(statement.timestamp).format(showDate ?'LL LTS':'LTS')}, ${
      statement.actor.name
    } ${statement.verb.display['en-US']} ${this.getObjectTypeStr(
      statement.object.definition.type
    )} ${statement.object.definition.name.und}`;
    if (statement.result) {
    str += ` with result: ${JSON.stringify(statement.result)}`;
    if (statement.result.completion) {
      str+='  <---- COMPLETED'
    }
    }
    switch (statement.verb.id) {
      case 'http://adlnet.gov/expapi/verbs/answered':
      break;
      case 'http://adlnet.gov/expapi/verbs/failed':
      break;
      case 'http://adlnet.gov/expapi/verbs/passed':
      break;
      default:
        // console.log(statement);
        break;
    }
    return str;
  }

}




