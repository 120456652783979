

export const environment = {
  production: false,
  awsconfig: {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "lms-contents-bucket-host-staging",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "d2l0f95v4146bd.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:d0784d9a-48d1-4d56-b985-ef7fa18bdbd7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_SAuihmCNg",
    "aws_user_pools_web_client_id": "2urtp4q0b0165ornqgn3s55ha8",
    "oauth": {}
  },
  LMS_SERVER_HOST: 'https://lms-staging.edriving.com',
  LMS_SERVER_PRIVATE_HOST: 'https://8x0p33qq10.execute-api.us-west-2.amazonaws.com/staging',
  LMS_SERVER_PRIVATE_HOST_PRODUCTION: 'https://w96bflzbgk.execute-api.us-west-2.amazonaws.com/prod',
  // LMS_SERVER_PRIVATE_HOST: 'https://nrd37ak7sc.execute-api.us-west-2.amazonaws.com/lms-proxy-staging',
  // LMS_SERVER_PRIVATE_HOST_PRODUCTION: 'https://2w2m206hnj.execute-api.us-west-2.amazonaws.com/lms-proxy-production',
  TEST_USER: '500058702',
  MAX_FILE_SIZE: 500000000,
  APP_BASSE: "/lmsadmin/"
}

