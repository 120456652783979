<div class="login-page">
  <div class="form">
    <form class="login-form" [formGroup]="loginForm">
      <div *ngIf="hasError" class="error_box">
        <div class="panel">
          <div class="panel panel-header ct-close">
            <span class="ct-close-x">X</span>
          </div>
          <div class="panel panel-body">{{error}}</div>
        </div>
      </div>
      <input type="text" placeholder="username" aria-label="username" matInput formControlName="username" required>
      <input type="password" placeholder="password" aria-label="password" matInput formControlName="password" required>

      <button type='submit' (click)="login()">login</button>
        <div class="forgot">
          <a class='link-forgot' (click)="forgot()">RESET MY PASSWORD</a>
        </div>
    </form>
  </div>
</div>