<form class='form-wrap'>
  <uploader key="{{content.content_key}}" (close)="uploadComplete($event)"></uploader>
  <form class="content-container" [formGroup]="contentForm" *ngIf='contentKeyExist()'>
      <p *ngIf="hasError" class="error_box">
       {{errorMessage}}</p>
      <div class="alert alert-danger" role="alert">
       {{postMessage}}
      </div>
    <mat-form-field>
      <input type='hidden' formControlName="content_key">
      <input type="text" placeholder="Course Category" aria-label="Category" class="readOnly"  matInput formControlName="category_name"
       readonly required >
    </mat-form-field>
    <mat-form-field>
      <input type="text" placeholder="Master Product Code" aria-label="Course" matInput class="readOnly" formControlName="course_name" readonly required >
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Title" formControlName="title" class="readOnly" readonly required  >
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Activity Id" formControlName="activity_id" class="readOnly" readonly required >
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Description" formControlName="description" class="readOnly" readonly required></textarea>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Author" formControlName="author" class="readOnly" readonly required >
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-select formControlName="authoring_tool" required>
        <mat-option *ngFor="let a of authoring_tools" value="{{a.code}}">{{a.text}}</mat-option>
      </mat-select>
      <mat-label>Authoring Tool</mat-label>
    </mat-form-field> -->
    <mat-form-field>
      <input matInput placeholder="Version" formControlName="version" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Duration" formControlName="course_duration" required>
    </mat-form-field>
    <mat-form-field>
      <input type="text" placeholder="Country/Region Code" aria-label="country" matInput formControlName="country"
       class="readOnly" readonly   required>
      
    </mat-form-field>
    <mat-form-field>
      <input type="text" placeholder="Language Code" aria-label="language" matInput class="readOnly" readonly 
        formControlName="language" required>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="user.js" formControlName="userjs" readonly ></textarea>
          </mat-form-field>
    <ng-content></ng-content>

  </form>
</form>