import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class UploadService {

  url: string;
  loaded = 0;
  total = 0;
  key: string;
  postupload: boolean;
  signedUrl: string;
  userjs: string;


  constructor(private http: HttpClient, private authService: AuthService) {

  }
  private updateProgress(progress: Subject<any>) {
    let percentDone = Math.round(100 * this.loaded / this.total);
    if (percentDone === 100) { percentDone = 99; }
    progress.next({ done: percentDone, key: this.key, userjs: this.userjs, postupload: this.postupload });
  }
  private completeProgress(progress: Subject<any>) {
    progress.next({ done: 100, key: this.key, userjs: this.userjs, postupload: this.postupload  });
    progress.complete();
    console.log('upload completed');
  }
  public triggerPostUpload(key: any, token: string) {
    const url = environment.LMS_SERVER_HOST + `/postupload?token=${token}&key=` + key;
    const req = new HttpRequest('GET', url);
    return this.http.request(req);
  }
  public upload(files: File[], key: string, token: string): Observable<any> {
    const formData: FormData = new FormData();
    let zip_file: File;
    let expect_signed = false;
    let url = `${environment.LMS_SERVER_HOST}/upload?token=${token}&key=` + key;
    console.log(files[0].size);
    if (files[0].size >  environment.MAX_FILE_SIZE) {
      url = url + '&signedUrl=true';
      zip_file = files.shift();
      expect_signed = true;
    }
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i], files[i].name);
    }
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });
    const progress = new Subject<any>();
    const serviceCall = this.http.request(req);
    serviceCall.subscribe(async event => {

      this.authService.clearTimeout();

      if (expect_signed) {
        this.loaded = files[0].size * 1 / 100;
        this.total = files[0].size;
        this.updateProgress(progress);
      }
      if (event.type === HttpEventType.UploadProgress) {
        this.loaded = event.loaded;
        this.total = event.total;
        if (!expect_signed) {
          this.updateProgress(progress);
        }

      } else if (event instanceof HttpResponse) {
        const body = event.body as any;
        //////////////////////////////// 1st ///////////////////////////////////
        this.key = body.key;
        this.userjs = body.userjs;
        this.postupload = body.postupload;
        this.signedUrl = body.signedUrl;
        ////////////////////////////////////////////////////////////////////////

        if (expect_signed) {// upload to S3 directly
          this.loaded = files[0].size * 1 / 100;
          this.total = files[0].size;
          this.updateProgress(progress);
          this.key = await this.uploadToSignedUrl(zip_file, body.signedUrl, progress) as string;
        }
        this.loaded = this.total;
        this.completeProgress(progress);
      } else if (event instanceof HttpErrorResponse) {
        console.log(JSON.stringify(event));
      }
    });
    return progress.asObservable();
  }

  public uploadToSignedUrl(file: File, signedUrl, progress) {
    return new Promise((resolve, reject) => {
      const req = new HttpRequest('PUT', signedUrl, file, // Send the File Blob as the POST body.
        {
          reportProgress: true
        });
      const serviceCall = this.http.request(req);
      serviceCall.subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          if (this.loaded < event.loaded) {
              this.loaded = event.loaded; // reflect the changes
              this.total = event.total;
              this.updateProgress(progress);
          }
        } else if (event instanceof HttpResponse) {
          this.completeProgress(progress);
          return resolve({}); // just resolve after uploading to S3 directly
        } else if (event instanceof HttpErrorResponse) {
          console.log(JSON.stringify(event));
          return reject();
        }
      });
    });
  }


}


