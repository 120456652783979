<app-nav-bar></app-nav-bar>
<mat-card class="pwrapper">
  <div>
    <div style="display:inline-block">
      <h1>Courses</h1>
    </div>

    <div style="float: right; margin:5px 15px">
      <button *ngIf='!editMode&&!isProd' mat-raised-button color="primary" class="btnSecondary" (click)="addNew()">
        <mat-icon svgIcon="add"></mat-icon> Add New Content
      </button>
      <button *ngIf='editMode' mat-raised-button color="primary" class="btnSecondary" (click)="editMode=false;">
        <mat-icon svgIcon="cancel"></mat-icon> Cancel
      </button>
    </div>


    <div style='clear:right'></div>
    <div *ngIf='!editMode' style="overflow-x: scroll">
      <mat-form-field style="width:auto">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <mat-paginator [pageSizeOptions]="[50, 100, 250, 1000]" style="display: inline-block;float: right;">
      </mat-paginator>
      <div class="dataGrid">
        <table mat-table [dataSource]="dataSourceCourse" matSort>
          <!-- <ng-container matColumnDef="category_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row"> {{row.category_name}} </td>
          </ng-container> -->
          <ng-container matColumnDef="course_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Product Code </th>
            <td mat-cell *matCellDef="let row"> {{row.course_name}} </td>
          </ng-container>
          <ng-container matColumnDef="activity_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Id </th>
            <td mat-cell *matCellDef="let row"> {{row.activity_id}} </td>
          </ng-container>
          <ng-container matColumnDef="content_title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title/Description </th>
            <td mat-cell *matCellDef="let row"> {{row.title}}<br /> {{row.description}} </td>
          </ng-container>
          <!-- <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
            <td mat-cell *matCellDef="let row"> {{row.version}} </td>
          </ng-container> -->
          <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
            <td mat-cell *matCellDef="let row"> {{row.language}} </td>
          </ng-container>
          <ng-container matColumnDef="locale">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
            <td mat-cell *matCellDef="let row"> {{row.country}} </td>
          </ng-container>
          <!-- <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
            <td mat-cell *matCellDef="let row"> {{row.course_duration}} </td>
          </ng-container> -->
          <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
            <td mat-cell *matCellDef="let row"> {{row.author.replace('@edriving.com','')}} </td>
          </ng-container>
          <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
            <td mat-cell *matCellDef="let row"> {{row.updated_at | date:'MM/dd/yyyy HH:mm' }} </td>
          </ng-container>
          <ng-container matColumnDef="pushed_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Push </th>
            <td mat-cell *matCellDef="let row"> {{row.pushed_at | date:'MM/dd/yyyy HH:mm' }} </td>
          </ng-container>
          <ng-container matColumnDef="uploaded_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded Date </th>
            <td mat-cell *matCellDef="let row"> {{row.uploaded_date | date:'MM/dd/yyyy HH:mm' }} </td>
          </ng-container>
          <ng-container matColumnDef="content_id">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">


              <table>
                <tr>
                  <td> <button mat-raised-button (click)="preview(row.content_id, row.activity_id)" class="btnSecondary"
                      color="accent">
                      <mat-icon svgIcon="play"></mat-icon> Preview
                    </button></td>
                  <td *ngIf="isProd"> <button mat-raised-button (click)="openConfirmActivate(row.activity_id)"
                      class="btnSecondary" color="accent" [disabled]="row.is_active">
                      <mat-icon svgIcon="visibility"></mat-icon> Activate
                    </button></td>
                  <td *ngIf="isProd"> <button mat-raised-button (click)="previewMeta(row.content_id, row.activity_id,'meta.xml')"
                      class="btnSecondary" color="accent" [disabled]="row.is_active">
                      <mat-icon svgIcon="info"></mat-icon> meta.xml
                    </button></td>
                  <td style="padding-left:20px" *ngIf="showEdit||!isProd">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon svgIcon="more"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button *ngIf="showEdit" mat-menu-item (click)="preview(row.content_id, row.activity_id, true)">
                        <mat-icon svgIcon="play"></mat-icon>
                        <span>Preview from Beginning</span>
                      </button>
                      <button *ngIf="showEdit" mat-menu-item (click)="resetTestUserCourseData(row.activity_id)">
                        <mat-icon svgIcon="delete"></mat-icon>
                        <span>Reset Test User Data</span>
                      </button>
                      
                      <button *ngIf="showEdit" mat-menu-item (click)="edit(row.content_id)">
                        <mat-icon svgIcon="edit"></mat-icon>
                        <span>Edit</span>
                      </button>
                      <button *ngIf="showEdit" mat-menu-item (click)="openConfirmDelete(row.content_id)">
                        <mat-icon svgIcon="delete"></mat-icon>
                        <span>Delete</span>
                      </button>
                      <button *ngIf="showEdit&&!isProd" mat-menu-item (click)="openConfirmPush(row.content_key)">
                        <mat-icon svgIcon="copy"></mat-icon>
                        <span>Push to Production</span>
                      </button>
                      <button *ngIf="!isProd" mat-menu-item (click)="previewMeta(row.content_id, row.activity_id,'meta.xml')">
                        <mat-icon svgIcon='info'></mat-icon>
                        <span>View Storyline meta.xml</span>
                      </button>
                      <button *ngIf="!isProd" mat-menu-item (click)="previewMeta(row.content_id, row.activity_id,'tincan.xml')">
                        <mat-icon svgIcon='info'></mat-icon>
                        <span>View Storyline tincan.xml</span>
                      </button>
                    </mat-menu>

                  </td>
                </tr>
              </table>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>

      </div>
    </div>
  </div>
  <mat-card *ngIf='editMode'>
    <content-detail detail="currentDetail" [courses]='courseNames' [categories]='categoryNames'
      (init)="onContentInit($event)" [content]='currentCourse'><button mat-raised-button color="primary"
        class="btnSecondary" type="submit" (click)="saveContent();">
        <mat-icon svgIcon="save"></mat-icon>Save
      </button></content-detail>
  </mat-card>

</mat-card>