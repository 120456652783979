import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseData } from '../../models/course-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UtilityService, Code } from '../../service/utility.service';
import { ContentService } from '../../service/content.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import { UploadService } from '../../service/upload.service';

@Component({
  selector: 'content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.css']
})
export class ContentDetailComponent implements OnInit {
  @Input()
  content: CourseData;
  @Input("categories")
  categories: string[];
  @Input("courses")
  courses: string[];

  @Output("init") init: EventEmitter<any> = new EventEmitter();
  editMode: boolean = false;
  public contentForm: FormGroup;
  countries: Code[];
  languages: Code[];
  authoring_tools: Code[];
  filteredCategories: Observable<any[]>;
  filteredCourses: Observable<any[]>;
  filteredCountries: Observable<any[]>;
  filteredLanguages: Observable<any[]>;
  hasError:boolean=false;
  errorMessage:string;
  postMessage: string;
  author:string;

  public activity: Subject<any>;
  constructor(private fb: FormBuilder, utility: UtilityService, private contentService: ContentService, private authService: AuthService, private router: Router, private uploadService: UploadService) {
    this.languages = utility.getLanguageCodes();
    this.countries = utility.getCountryCodes();
    this.authoring_tools = utility.getAuthorinTools();
    this.activity = new Subject<any>();
    this.init = new EventEmitter();
    let user = sessionStorage.getItem('user');
    if (!user) this.router.navigate(['login']);
    let userObj: any = JSON.parse(user);
    this.author=userObj.username;
    this.postMessage = "";
  }
  public resetContent() {
    this.postMessage ="";
    if (!this.content) {
      console.log(this.content)
    }
    this.contentForm = this.fb.group(this.content as any);
  }

  contentKeyExist() {
    if (this.contentForm.controls.content_key.value) {
      if (this.contentForm.controls.content_key.value.length > 0) {
        return true;
      }
    }
    return false;
  }
  ngOnInit() {
    this.activity.asObservable().subscribe(d => {
      this.init.emit({
        data: d
      })
    })
    this.resetContent();
    this.filteredCategories = this.contentForm.controls.category_name.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCategories(value))
      );
    this.filteredCourses = this.contentForm.controls.course_name.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCourses(value))
      );
    this.filteredCountries = this.contentForm.controls.country.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filteredCountries(value))
      );
    this.filteredLanguages = this.contentForm.controls.language.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filteredLanguages(value))
      );
  }
  private _filterCategories(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.categories.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterCourses(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.courses.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filteredCountries(value: string): Code[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.text.toLowerCase().includes(filterValue));
  }
  private _filteredLanguages(value: string): Code[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter(option => option.text.toLowerCase().includes(filterValue));
  }
  private _delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  private async _getCourseActivity(key,postupload,userjs) {
 
    if (key) {
      if (postupload) {
        console.log('postupload')
        console.log(postupload);
        this.postMessage=`Processing a file larger than 100M require manual post process. Please save this result, and contact the system administrator with the content key, [${key}].`

        this.contentService.getToken().subscribe(async (event) => {
          if (event instanceof HttpResponse) {
            await this.uploadService.triggerPostUpload(key, event.body.token).toPromise();
          }
        });
      }
      this.contentForm.controls.content_key.setValue(key);
      this.contentForm.controls.userjs.setValue(userjs)
      await this._delay(100);
      let data = await this.contentService.getCourseActivityByKey(key).toPromise() as any;
      if (!data.body.activity) {
        this._getCourseActivity(key, postupload, userjs);
        return;
      }
     
      this.contentForm.controls.title.setValue(data.body.activity.name);
      this.contentForm.controls.description.setValue(data.body.activity.description);
      if(data.body.activity.description.indexOf('ERROR:')>0){
        this.contentForm.controls.description.setValue('');
        this.hasError=true;
        this.errorMessage = data.body.activity.description.substring(data.body.activity.description.indexOf('ERROR:') + 6);
      }else{
      let tmp = data.body.activity.activity_id;
      tmp=tmp.replace('http://','');
      let parts=tmp.split('-');
      let category_name= parts[0];
      let language=parts[4];
      let country='';
      let countryCode=[];
      for(var i=5; i<parts.length;i++){
      countryCode.push(parts[i]);
      }
      country = countryCode.join('-');
      let course_name=[parts[0],parts[1],parts[2],parts[3]].join('-');
      this.contentForm.controls.course_name.setValue(course_name);
      this.contentForm.controls.category_name.setValue(category_name);
      this.contentForm.controls.country.setValue(country);
      this.contentForm.controls.language.setValue(language);

      }
      this.contentForm.controls.activity_id.setValue(data.body.activity.activity_id);
      this.contentForm.controls.author.setValue(this.author);
      this.activity.next(data.body.activity);
    }
  }
  uploadComplete(evt) {// upload component emits!
    console.log(evt)
    this._getCourseActivity(evt.key, evt.postupload, evt.userjs);
  }
  submit() {
    this.editMode = false;
  }

}
