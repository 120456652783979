import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NgModule } from '@angular/core';

import {
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import {
  MatBadgeModule
} from '@angular/material/badge';
import {
  MatBottomSheetModule
} from '@angular/material/bottom-sheet';
import {
  MatButtonToggleModule
} from '@angular/material/button-toggle';

import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatCardModule
} from '@angular/material/card';

import {
  MatCheckboxModule
} from '@angular/material/checkbox';
import {
  MatChipsModule
} from '@angular/material/chips';
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import {
  MatDialogModule
} from '@angular/material/dialog';
import {
  MatDividerModule
} from '@angular/material/divider';

import {
  MatExpansionModule
} from '@angular/material/expansion';
import {
  MatGridListModule
} from '@angular/material/grid-list';
import {
  MatIconModule
} from '@angular/material/icon';
import {
  MatInputModule
} from '@angular/material/input';

import {
  MatListModule
} from '@angular/material/list';
import {
  MatMenuModule
} from '@angular/material/menu';

import {
  MatPaginatorModule
} from '@angular/material/paginator';
import {
  MatTableModule
} from '@angular/material/table';
import {
  MatProgressBarModule
} from '@angular/material/progress-bar';

import {
  MatSortModule
} from '@angular/material/sort';
// ,
//   MatRadioModule,
//   MatRippleModule,
//   MatSelectModule,
//   MatSidenavModule,
//   MatSliderModule,
//   MatSlideToggleModule,
//   MatSnackBarModule,
//   MatSortModule,
//   MatStepperModule,
//   MatTableModule,
//   MatTabsModule,
//   MatToolbarModule,
//   MatTooltipModule,
//   MatTreeModule,
@NgModule({
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
  
    MatPaginatorModule,
    MatProgressBarModule,
   
    MatSortModule,
  
    PortalModule,
    ScrollingModule,
  ]
})
export class NgMaterialsModule { }
