import { Component, OnInit, ViewChild } from '@angular/core';
import { ContentService } from '../../service/content.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../service/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MasterCourseData } from '../../models/course-master';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-course-inventory',
  templateUrl: './course-inventory.component.html',
  styleUrls: ['./course-inventory.component.css']
})
export class CourseInventoryComponent implements OnInit {
  dataSourceCourse: MatTableDataSource<MasterCourseData>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'masterProductCode',
    'description',
    'en_US',
    'en_053',
    'es_419',
    'es_US',
    'pt_BR',
    'fr_CA',
    'en_CA',
    'es_150',
    'fr_150',
    'de_150',
    'it_150',
    'ru_150',
    'pl_150',
    'nl_150',
    'pt_150',
    'el_150',
    'tr_150',
    'cs_150',
    'sk_150',
    'ro_150',
    'hu_150',
    'fr_BE',
    'nl_BE',
    'bg_150',
    'hr_150',
    'da_150',
    'fi_150',
    'sr_150',
    'sv_150',
    'jp_JP',
    'zh_142',
    'zh_Hant_142',
    'vi_142',
    'ko_142',
    'th_142',
    'in_142'];
  csv: string;
  data: MasterCourseData[] = [];

  constructor(private contentService: ContentService, private authService: AuthService) { }

  ngOnInit() {
    this.reloadContets();
    
  }
  reloadContets() {

    const langs = [
      'en-US',
      'en-053',
      'es-419',
      'es-US',
      'pt-BR',
      'fr-CA',
      'en-CA',
      'es-150',
      'fr-150',
      'de-150',
      'it-150',
      'ru-150',
      'pl-150',
      'nl-150',
      'pt-150',
      'el-150',
      'tr-150',
      'cs-150',
      'sk-150',
      'ro-150',
      'hu-150',
      'fr-BE',
      'nl-BE',
      'bg-150',
      'hr-150',
      'da-150',
      'fi-150',
      'sr-150',
      'sv-150',
      'jp-JP',
      'zh-142',
      'zh-Hant-142',
      'vi-142',
      'ko-142',
      'th-142',
      'in-142',
    ];

    this.contentService.getAllCourseContents().subscribe((event) => {
      if (event instanceof HttpResponse) {
        var courseContentMap = {};//masterProductCode-{lang, course}

        event.body.data.map(function (courseContent) {
          if (!courseContentMap[courseContent.course_name]) {
            courseContentMap[courseContent.course_name] = {
              masterProductCode: courseContent.course_name
            };

          }
          for (var i = 0; i < langs.length; i++) {
            if (courseContent.activity_id.toUpperCase().endsWith(langs[i].toUpperCase())) {
              if (langs[i] == 'en-US') {
                courseContentMap[courseContent.course_name].title = courseContent.title;
                courseContentMap[courseContent.course_name].description = courseContent.description;
              }
              courseContentMap[courseContent.course_name][langs[i].replace('-', '_')] = courseContent.is_active == 1 ? 'A' : 'U';
              break;
            }
          }
        })
        this.data = Object.values(courseContentMap);
        console.log(this.data)
        this.dataSourceCourse = new MatTableDataSource(this.data);

        var masterProductCodes = Object.keys(courseContentMap).sort();
        this.dataSourceCourse.paginator = this.paginator;
        this.dataSourceCourse.sort = this.sort;
        this.paginator.pageIndex = 0;
        var csvString = [];
        var row = [];
        row.push('master_product_code');
        row.push('desc');
        for (var i = 0; i < langs.length; i++) {
          row.push(langs[i]);
        }
        csvString.push(row.join(','));
        for (var i = 0; i < masterProductCodes.length; i++) {
          var row = [];
          row.push('"' + masterProductCodes[i] + '"');
          row.push(courseContentMap[masterProductCodes[i]].COURSE ? ('"' + courseContentMap[masterProductCodes[i]].COURSE.title + ' - ' + courseContentMap[masterProductCodes[i]].COURSE.description + '"') : '');
          for (var j = 0; j < langs.length; j++) {
            row.push(courseContentMap[masterProductCodes[i]][langs[j]]);
          }
          csvString.push(row.join(','));
        }
        this.csv = csvString.join('\n');
      } else if (event instanceof HttpErrorResponse) {
        console.log(event);
      }
    });
    
  }
  applyFilter(filterValue: string) {
    this.dataSourceCourse.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceCourse.paginator) {
      this.dataSourceCourse.paginator.firstPage();
    }
  }
}
