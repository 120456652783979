import { Injectable, ModuleWithComponentFactories } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
const moment = require('moment');
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public elapsed: number;
  timedout: boolean;
  public inactiveInterval = 60 * 30;
  constructor(private router: Router) {
    this.userInactive.subscribe((now) => {
      this.elapsed = now.diff(this.previousTime, 'seconds');
      console.log('inactive', this.elapsed);
      if (this.elapsed > this.inactiveInterval) {
        console.log(`user has been inactive for ${this.elapsed}`);
        this.timedout = true;
        this.logOut();
        return;
      }
      this.refreshToken();
      this.setTimeout();
    }
    );

  }
  userActivity;
  userInactive: Subject<any> = new Subject();
  previousTime = moment();
  congitoUser = new BehaviorSubject(null);

  refreshInterval = 35;
  lastRefreshed = moment();
  logOut() {
    Auth.signOut({ global: true })
      .then(data => { })
      .catch(err => console.log(err));
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
  }
  forgot(username) {
    sessionStorage.setItem('email', username);
    Auth.forgotPassword(username)
      .then(data => { this.router.navigate([`passwordreset/true`]); })
      .catch(err => console.log(err));

  }
 async getUserName():Promise<string>{
    const user = await Auth.currentUserInfo();
    console.log(user)
   return user.username;
  }
  
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(moment()), 1000);
  }
  clearTimeout() {
    this.elapsed = 0;
    clearTimeout(this.userActivity);
    this.previousTime = moment();
    this.refreshToken();
  }
  async refreshToken() {
    this.isAuthenticated().then(async authed => {

      if (authed) {
        if (this.timedout) {
          return;
        }
        try {
        
          const now = moment();
          const diff = now.diff(this.lastRefreshed, 'seconds');
         
          if (this.refreshInterval < diff) {
            console.log('refresh');
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
              //   console.log('session',  session);
              if (err) {
                console.log('Unable to refresh Token', err);
                this.logOut();
              }
              this.lastRefreshed = now;

            });
          }
        } catch (e) {
          console.log('Unable to refresh Token', e);
          this.router.navigate(['login']);
          this.logOut();
          return;
        }
      }
    })

  }

  public async isAuthenticated() {
    return new Promise(async function (resolve) {
      return await Auth.currentAuthenticatedUser()
        .then(() => { return resolve(true); })
        .catch(() => { return resolve(false); });
    })

  }

  public authenticate(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password).then((user) => {

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.congitoUser.next(user);
          this.router.navigate([`passwordreset/false`]);
          return reject(1);
        } else {
          sessionStorage.setItem('user', JSON.stringify(user));
          this.clearTimeout();
          this.timedout = false;
          return resolve(user);
        }
      }).catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === 'PasswordResetRequiredException') {
          sessionStorage.setItem('email', username);
          Auth.forgotPassword(username)
            .then(data => { this.router.navigate([`passwordreset/true`]); })
            .catch(err => console.log(err));

        } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
        } else {
          console.log(err);
        }
        return reject(err);
      }
      ).finally(() => reject({ message: 'unknown error' }));
    });
  }
  public resetPassword(username, code, new_password) {
    return Auth.forgotPasswordSubmit(username, code, new_password);

  }
  public newpassword(username, password) {
    return new Promise(async (resolve, reject) => {
      this.congitoUser.subscribe((value) => {
        Auth.completeNewPassword(
          value,               // the Cognito User Object
          password,       // the new password
          // OPTIONAL, the required attributes
          {

          }
        ).then(compltetd_user => {
          return resolve(compltetd_user);
        }).catch(e => {
          return reject(e);
        });
      })
    });
  }




}
