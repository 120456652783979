<mat-toolbar class="topNav">
  <mat-toolbar-row>
    <div style="position: relative; width: 100%;">
      <div style="position: relative; display:inline-block">
        <a mat-button [routerLink]="'/contents'"> Courses </a>
        <a mat-button [routerLink]="'/qalinks'"> QA Links </a>
        <a mat-button [routerLink]="'/courseinventory'"> Languages </a>
      </div>
      <div style="position: absolute; right: 2rem;display:inline-block">
        <button mat-raised-button color="" (click)="logOut()">
          <mat-icon svgIcon="logout"></mat-icon> Logout
        </button>
      </div>
      </div>
  </mat-toolbar-row>
</mat-toolbar>