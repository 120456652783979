import { Component, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadService } from '../../../service/upload.service';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ZipService } from '../../../service/zip.service';
import { ContentService } from '../../../service/content.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'upload-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

  uploadingStatus;
  canBeClosed = true;
  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  updateProgress: Subject<number>;
  uploadIndicator: Observable<number>;
  done:number;
  public key: string;
  token: string;
  xml:File;
  userjs: File;

  constructor(public dialogRef: MatDialogRef<DialogComponent>, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private uploadService: UploadService, private contentService: ContentService, private zipService: ZipService) {
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/baseline-edit-24px.svg'));
    iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('../../../../appassets/icons/baseline-delete_forever-24px.svg'));
    iconRegistry.addSvgIcon('play', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/baseline-play_circle_outline-24px.svg'));
    iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/baseline-add_circle_outline-24px.svg'));
    iconRegistry.addSvgIcon('save', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/baseline-save_alt-24px.svg'));
    iconRegistry.addSvgIcon('cancel', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/baseline-cancel-24px.svg'));
    this.updateProgress = new Subject<number>();
    this.uploadIndicator = this.updateProgress.asObservable();
    this.uploadedFile=[];
  }

  @ViewChild('file') file;

  public uploadedFile: File[];
  contentProviders = [{ text: "Adobe Captivate", value: "Captivate" }, { text: "Articulate Storyline", value: "Storyline" }];

  addFile() {
    this.file.nativeElement.click();
  }
  blobToText(blb){
    return new Promise((resolve,reject)=>{
      const reader = new FileReader();
      reader.addEventListener('loadend', (e:any) => {
        const text = e.srcElement.result;
        console.log(text);
        resolve(text);
      });
      try{
      reader.readAsText(blb);
      }catch(ex){
        reject(ex);
      }

    })
  }
  
  async onFileAdded() {
    
    this.uploadedFile.push((this.file.nativeElement.files[0]));
    this.uploading = true;
  
    let entries = await this.zipService.getEntries(this.uploadedFile[0]).toPromise();
    for (let i = 0; i < entries.length; i++) {
      let e = entries[i];
      console.log(e.filename)
      if (e.filename=='tincan.xml') {
        let blob = await this.zipService.getData(e).data.toPromise();
        this.xml = new File([blob], "tincan.xml");
        this.uploadedFile.push(this.xml);
      }
      if (e.filename == 'story_content/user.js') {
        let blob = await this.zipService.getData(e).data.toPromise();
        this.userjs = new File([blob], "user.js");
        this.uploadedFile.push(this.userjs);
      }
    }
    this.contentService.getToken().subscribe((event) => {
      if (event instanceof HttpResponse) {
        console.log(event.body)
        this.token = event.body.token;
        this.uploadingStatus = this.uploadService.upload(this.uploadedFile, this.key, this.token);
        // console.log('uploadingStatus');
        // console.log(this.uploadingStatus);
        this.primaryButtonText = 'Finish';
        this.canBeClosed = false;
        this.dialogRef.disableClose = true;
        this.showCancelButton = false;

        this.uploadingStatus.subscribe(state => {//Service.upload's return and it is the observable
          let val = state.done;
          this.done=val;
          this.updateProgress.next(val);
          if (val == 100) {
            this.canBeClosed = true;
            this.dialogRef.disableClose = false;
            this.uploadSuccessful = true;
            this.uploading = false;

            setTimeout(() => {
              //UPLOAD-COMPLETE
              this.dialogRef.close({ key: state.key, postupload: state.postupload, userjs:state.userjs  });//pssing the key back
            }, 100);
          }
        });

      } else if (event instanceof HttpErrorResponse) {
        console.log(event);
      }
    });


  }
}