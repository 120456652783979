<div class="form-wrap">
  <h1>Create a Course Link</h1>
  <!-- 
  <input class="txt" placeholder="User Id" [(ngModel)]="user_id" required>

  <div class="divider">&nbsp;</div> -->

  <input class="txt" placeholder="Activity Id" [(ngModel)]="activity_id" required>

    <div class="divider">&nbsp;</div><br/>
    
    <input class="txt" placeholder="Return URL" [(ngModel)]="return_url" >

  <div class="divider">&nbsp;</div><br/>

  <input type="checkbox"  id="qalink" [(ngModel)]="qalink"><label for="qalink"> QA Link</label><br/>

  <div   *ngIf="qalink">
    Expires In <select  class="txt" style="width: 200px;" [(ngModel)]="period">
      <option value="0.01666666666">1 min</option>
      <option value="1">1 hour</option>
      <option value="24">1 day</option>
      <option value="72">3 days</option>
      <option value="168">1 week</option>
      <option value="336">2 weeks</option>
    </select>
  </div>
  

  <div class="divider">&nbsp;</div>
  <div *ngIf="hasMessage" style="color:green;">QA link is successfully disabled!</div>
  <div *ngIf="hasError" style="color:red;">Disabling QA link failed. Please retry!</div>
  <br/>
  <button mat-raised-button color="primary" class="btnSecondary" (click)="create();">
    <mat-icon svgIcon="link"></mat-icon> Create a Link for User
  </button>

  <div class="divider">&nbsp;</div>
  <br/><br/>
  <h1>Disable a Course Link</h1>
  
  <input class="txt" style="min-width: 400px;" placeholder="Token" [(ngModel)]="qa_token" required><br/>
  <button mat-raised-button color="primary" class="btnSecondary" (click)="disable();">
    <mat-icon svgIcon="link"></mat-icon> Disable QA link
  </button>
</div>
