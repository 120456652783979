import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from '../../service/upload.service';
import { HttpClientModule } from '@angular/common/http';
import { UploadComponent } from './upload.component';
//FlexLayoutModule, 
@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule,MatIconModule, MatListModule, HttpClientModule, BrowserAnimationsModule, MatProgressBarModule,FormsModule],
  declarations: [DialogComponent, UploadComponent],
  exports: [DialogComponent, UploadComponent],
  entryComponents: [DialogComponent],
  providers: [UploadService]
})
export class UploadModule {}