<app-nav-bar></app-nav-bar>

<mat-card class="pwrapper">
    <div style="display:block">
        <h1>Languages</h1>
    </div>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-paginator [pageSizeOptions]="[250, 500]" style="display: inline-block;float: right;"></mat-paginator>
    <div class='dataGrid' >
        <div class="mat-elevation-z8">
            
            <table mat-table [dataSource]="dataSourceCourse" matSort>
              
                <ng-container matColumnDef="masterProductCode" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Product Code </th>
                    <td mat-cell *matCellDef="let row" style="text-align: left;min-width:200px"> {{row.masterProductCode}} </td>
                </ng-container>
                <ng-container matColumnDef="title" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                    <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                </ng-container>
                <ng-container matColumnDef="description" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                    <td mat-cell *matCellDef="let row" style="text-align: left;min-width:300px"> {{row.title}}<br/>{{row.description}} </td>
                </ng-container>

                <ng-container matColumnDef="en_US">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>en-US</th>
                    <td mat-cell *matCellDef="let row"> {{row.en_US}} </td>
                </ng-container>
                <ng-container matColumnDef="en_053">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>en-053</th>
                    <td mat-cell *matCellDef="let row"> {{row.en_053}} </td>
                </ng-container>
                <ng-container matColumnDef="es_US">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>es-us</th>
                    <td mat-cell *matCellDef="let row"> {{row.es_US}} </td>
                </ng-container>
                <ng-container matColumnDef="es_419">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>es-419</th>
                    <td mat-cell *matCellDef="let row"> {{row.es_419}} </td>
                </ng-container>
                <ng-container matColumnDef="pt_BR">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>pt-BR</th>
                    <td mat-cell *matCellDef="let row"> {{row.pt_BR}} </td>
                </ng-container>
                <ng-container matColumnDef="fr_CA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>fr-CA</th>
                    <td mat-cell *matCellDef="let row"> {{row.fr_CA}} </td>
                </ng-container>
                <ng-container matColumnDef="en_CA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>en-CA</th>
                    <td mat-cell *matCellDef="let row"> {{row.en_CA}} </td>
                </ng-container>
                <ng-container matColumnDef="es_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>es-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.es_150}} </td>
                </ng-container>
                <ng-container matColumnDef="fr_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>fr-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.fr_150}} </td>
                </ng-container>
                <ng-container matColumnDef="de_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>de-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.de_150}} </td>
                </ng-container>
                <ng-container matColumnDef="it_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>it-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.it_150}} </td>
                </ng-container>
                <ng-container matColumnDef="ru_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ru-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.ru_150}} </td>
                </ng-container>
                <ng-container matColumnDef="pl_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>pl-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.pl_150}} </td>
                </ng-container>
                <ng-container matColumnDef="nl_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>nl-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.nl_150}} </td>
                </ng-container>
                <ng-container matColumnDef="pt_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>pt-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.pt_150}} </td>
                </ng-container>
                <ng-container matColumnDef="el_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>el-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.el_150}} </td>
                </ng-container>
                <ng-container matColumnDef="tr_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>tr-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.tr_150}} </td>
                </ng-container>
                <ng-container matColumnDef="cs_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>cs-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.cs_150}} </td>
                </ng-container>
                <ng-container matColumnDef="sk_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>sk-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.sk_150}} </td>
                </ng-container>
                <ng-container matColumnDef="ro_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ro-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.ro_150}} </td>
                </ng-container>
                <ng-container matColumnDef="hu_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>hu-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.hu_150}} </td>
                </ng-container>
                <ng-container matColumnDef="fr_BE">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>fr-BE</th>
                    <td mat-cell *matCellDef="let row"> {{row.fr_BE}} </td>
                </ng-container>
                <ng-container matColumnDef="nl_BE">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>nl-BE</th>
                    <td mat-cell *matCellDef="let row"> {{row.nl_BE}} </td>
                </ng-container>
                <ng-container matColumnDef="bg_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>bg-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.bg_150}} </td>
                </ng-container>
                <ng-container matColumnDef="hr_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>hr-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.hr_150}} </td>
                </ng-container>
                <ng-container matColumnDef="da_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>da-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.da_150}} </td>
                </ng-container>
                <ng-container matColumnDef="fi_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>fi-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.fi_150}} </td>
                </ng-container>
                <ng-container matColumnDef="sr_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>sr-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.sr_150}} </td>
                </ng-container>
                <ng-container matColumnDef="sv_150">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>sv-150</th>
                    <td mat-cell *matCellDef="let row"> {{row.sv_150}} </td>
                </ng-container>
                <ng-container matColumnDef="jp_JP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>jp-JP</th>
                    <td mat-cell *matCellDef="let row"> {{row.jp_JP}} </td>
                </ng-container>
                <ng-container matColumnDef="zh_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>zh-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.zh_142}} </td>
                </ng-container>
                <ng-container matColumnDef="zh_Hant_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>zh-Hant-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.zh_Hant_142}} </td>
                </ng-container>
                <ng-container matColumnDef="vi_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>vi-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.vi_142}} </td>
                </ng-container>
                <ng-container matColumnDef="ko_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ko-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.ko_142}} </td>
                </ng-container>
                <ng-container matColumnDef="th_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>th-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.th_142}} </td>
                </ng-container>
                <ng-container matColumnDef="in_142">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>in-142</th>
                    <td mat-cell *matCellDef="let row"> {{row.in_142}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            
        </div>
    </div>
</mat-card>