<div class="login-page">
  <div class="form">
    <div  *ngIf="hasCode=='true'" style="color:rgb(245, 109, 109); padding-top: 30px;font-size:16px;margin-bottom: 50px;">
    
      Please check your email inbox for verification code.
    
    </div>

    <form class="login-panel" [formGroup]="resetForm">
      <div class="login-form">
        <div *ngIf="hasError" class="error_box">
          <div class="panel">
            <div class="panel panel-header ct-close">
              <span class="ct-close-x">X</span>
            </div>
            <div class="panel panel-body">{{error}}</div>
          </div>
        </div>
        <label  *ngIf="hasCode=='true'">UserName/Email Address</label>
        <input type="text" class="login-input" aria-label="username" formControlName="username" required
          *ngIf="hasCode=='true'">
    
        <label  *ngIf="hasCode=='true'">Verification Code</label>
    
        <input type="password" class="login-input" aria-label="code" formControlName="code" required
          autocomplete="new-password" *ngIf="hasCode=='true'">
    
        <label>New Password</label>
    
        <input type="password" class="login-input" aria-label="password" formControlName="password" required>
    
        <label>Retype Password</label>
    
        <input type="password" class="login-input" aria-label="password" formControlName="repassword" required>
    
    
    
      </div>
    
      <div class="login-link">
        <button class='btn-login' type='submit' (click)="submit()">Reset Password</button>
      </div>
    
    </form>
   
  </div>
</div>


